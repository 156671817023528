'use client';

import { Bridge, PageTitle, SeasonDetailsBanner, ExternalLinkIcon, ExternalLinkButton } from '@/components';
import { ExternalLinkWarning } from '@/components/ExternalLinkWarning';

export default function Home() {
  return (
    <main className="text-neutral-600 w-xs-full w-m-1/2 md:py-16 max-w-[600px] mx-auto p-4 md:p-0 animate-appear">
      <SeasonDetailsBanner
        title="Try out Global Address"
        text={
          <>
            Create a Global Address and send USDC, USDT, WBTC, WETH, or ETH from any of the
            <ExternalLinkWarning className="underline ml-1" href="https://global-address.zerodev.app/">
              supported chains
            </ExternalLinkWarning>
            , and receive it on your wallet on Mode!
          </>
        }
        label="New"
        icon="/assets/icons/zerodev-icon.svg"
        className="mb-8"
        extraButtonLink="https://global-address.zerodev.app/"
        extraButtonText={
          <>
            Get your Global Address <ExternalLinkIcon className="ml-2" />
          </>
        }
        buttonVariant="primary"
        backgroundImage="/assets/images/zerodev-banner-bg.png"
      />
      <PageTitle>Bridge</PageTitle>
      <Bridge />
    </main>
  );
}
